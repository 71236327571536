@font-face {
  font-family: "CIBFont Sans Regular";
  src: url("Fonts/CIBFontSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Bold";
  src: url("Fonts/CIBFontSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Italic";
  src: url("Fonts/CIBFontSans-Italic.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "CIBFont Sans Book";
  src: url("Fonts/CIBFontSans-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.titulo {
  font-family: "CIBFont Sans Bold";
  font-size: 200%;
}

.subtitulo {
  font-family: "CIBFont Sans Regular";
  font-size: 160%;
}
.label {
  font-family: "CIBFont Sans Regular";
  font-size: 140%;
}
.link {
  font-family: "CIBFont Sans Bold";
  font-size: 140%;
  color: white;
}
.link:hover {
  color: white !important;
}
.link2 {
  font-family: "CIBFont Sans Bold";
  font-size: 140%;
  color: rgb(154, 157, 160);
}
.link2:hover {
  color: white !important;
  text-decoration: none;
}
.texto {
  font-size: 110%;
}
.error {
  color: red;
}
.titulo_resumen {
  font-family: "CIBFont Sans Bold";
  font-size: 120%;
}
.form-control {
  font-size: 100% !important;
}

.botonPositivo {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonPositivo2 {
  color: #fff !important;
  background-color: #00c389 !important;
  border-color: #00c389 !important;
}
.botonNegativo {
  color: #fff !important;
  background-color: #ff7f41 !important;
  border-color: #ff7f41 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonNegativo2 {
  color: #fff !important;
  background-color: #ff7f41 !important;
  border-color: #ff7f41 !important;
}

.botonNegativo4 {
  color: #2c2a29 !important;
  background-color: #fdd921 !important;
  border-color: #fdd921 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonNegativo3 {
  color: #fff !important;
  background-color: #b263d6 !important;
  border-color: #b263d6 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonGeneral {
  color: #fff !important;
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonGeneral2 {
  color: #fff !important;
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
}
.botonGeneral3 {
  color: #fff !important;
  background-color: #2c2a29 !important;
  border-color: #2c2a29 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}
.botonIngreso {
  color: #fff !important;
  background-color: #59cbe8 !important;
  border-color: #59cbe8 !important;
  /* min-width: 17.9045093vw;
  min-height: 4.6419098144vh; */
}

.makeStyles-toolbar-2 {
  border-bottom: solid;
  border-bottom-color: #fdda24;
  border-bottom-width: 0.5vw;
}

#contenedor_paginas {
  padding: 2%;
  overflow-x: hidden;
}

.alert {
  width: 37vw;
  left: 60vw;
  position: fixed !important;
  z-index: 100;
}
.alert-hidden {
  opacity: 0;
  transition: all 250ms linear 1s;
}
.buscar {
  padding: 1%;
  padding-left: 35px;
  padding: 1%;
}

.contenedorArbol {
  max-height: 40vh;
  overflow-y: scroll;
}
.contenedorGrid {
  max-height: 80vh;
  overflow-y: scroll;
}

.contenedorVector {
  max-height: 35vh;
  overflow-y: scroll;
}

.tr {
  height: 10px;
}
.separador {
  border-top: 1px solid #757d75;
}

.sticky {
  position: fixed;
}

.my-modal {
  width: 90vw;
  max-width: 90vw;
}

.input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 1em; /* 1em -> input font-size * 1 -> 40px * 1 = 40px */
}

.input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 1em;
}

.input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 1em;
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 1em;
}

.caro-chat {
  z-index: 10000000;
  position: absolute;
  right: 90vw;
}

.fixed-bottom {
  position: "absolute";
  bottom: 100px;
  left: 0;
}

.container_caro {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* chat box section */

.chat-box {
  width: 20vw;
  height: 75vh;
  background-color: #fff;
  overflow: hidden;
  border-radius: 3vh;
  border-color: #00000066;
  border-style: solid;
  top: 12vh;
  position: fixed;
}

/* about client */

.client {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 12vh;
  background-color: #3d3b3a;
  padding: 5%;
}

.client img {
  width: 5vw;
  height: 8vh;
}

.client-info {
  color: #fff;
  font-weight: bold;
  padding: 15px;
}

.client-info p {
  color: #008000;
  font-weight: bold;
  font-size: 0.8em;
}

/* chat section */

.chats {
  overflow-y: auto;
  width: 100%;
  height: 66%;

  font-size: 0.9em;
}

.client-chat {
  width: 80%;
  word-wrap: break-word;
  font-weight: bold;
  background-color: #f5d223;
  padding: 7px 10px;
  border-radius: 10px 10px 10px 0;
  margin: 10px 0;
  color: rgb(0, 0, 0);
}

.my-chat {
  width: 30%;
  word-wrap: break-word;
  background-color: #3d3b3a;
  padding: 7px 10px;
  border-radius: 10px 10px 0 10px;
  margin: 5px 0 5px auto;
  color: #fff;
  font-weight: bold;
}

/* input section */

.chat-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8vh;
  background-color: #fff;
  padding: 15px;
  position: absolute;
  bottom: 0;
}

.chat-input input {
  width: calc(100% - 40px);
  height: 100%;
  background-color: #4f5d7321;
  border-radius: 50px;
  color: #000;
  font-size: 1.2em;
  padding: 0 15px;
}

.send-btn {
  width: 40px;
  height: 40px;
  background-color: transparent;
  overflow: hidden;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  color: #2c2a29;
}

.send-btn:active {
  transform: scale(0.85);
}

.send-btn img {
  width: 100%;
  height: 100%;
}

/* chat box button section */

.chat-btn {
  width: 8vw;
  height: 20vh;
  border-radius: 50%;
  background-color: rgba(40, 40, 40, 0.597);
  cursor: pointer;
  overflow: hidden;
  position: fixed;
  bottom: -7vh;
}

.chat-btn img {
  width: 8vw;
  height: 13vh;
  border-radius: 1%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  transition: 0.4s ease-in-out;
}

.chat-btn:hover img {
  transform: rotate(15deg);
}

.disabled_link {
  pointer-events: none;
  cursor: default;
  opacity: 0.7;
}

buttonPositivo {
  background: linear-gradient(-45deg, #fdda24, #00c389, #ff7f41, #59cbe8);
  background-size: 800% 400%;
  padding: 1.5vh 1.5vh;
  display: inline-block;
  border: none;
  border-radius: 1px;
  width: 100%;
  height: 1%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}

buttonPositivo:hover {
  animation: gradient 3s infinite;
  transform: scale(1.05);
}

buttonPositivo:active {
  animation: gradient 3s infinite;
  transform: scale(0.8);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.result-tolerable{
  background: #0fb100 !important;
  color: #ffffff !important;
}

.result-moderado{
  background: #e6d600 !important;
  color: #ffffff !important;
}

.result-critico{
  background: #cc0000 !important;
  color: #ffffff !important;
}